<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <div>
          <form-wizard
            :subtitle="null"
            :title="null"
            back-button-text="Previous"
            class="mb-3"
            color="#7367F0"
            finish-button-text="Submit"
            shape="square"
            @on-complete="formSubmitted"
          >

            <!-- Basic Info tab -->
            <tab-content
              :before-change="validationForm"
              title="Basic Information"
            >
              <validation-observer
                ref="studentBasicInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="student Name"
                      label-for="student"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="student Name"
                        rules="required"
                      >
                        <b-form-input
                          id="student"
                          v-model="student.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Designation"
                      label-for="designation"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          id="designation"
                          v-model="student.designation"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your Designation"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="student.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Description"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Registered Name"
                      label-for="registered_title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Registered Name"
                        rules="required"
                      >
                        <b-form-input
                          id="registered_title"
                          v-model="student.registered_title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Registered Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="CIN"
                      label-for="cin"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="CIN"
                        rules="required"
                      >
                        <b-form-input
                          id="cin"
                          v-model="student.cin"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Registration Number"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="DPIIT"
                      label-for="dpiit"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="DPIIT"
                        rules="required"
                      >
                        <b-form-input
                          id="dpiit"
                          v-model="student.dpiit"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student DPIIT Number"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Official Email"
                      label-for="official_email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Official Email"
                        rules="required"
                      >
                        <b-form-input
                          id="official_email"
                          v-model="student.official_email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="hello@yourbusiness.com"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Phone Number"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="required"
                      >
                        <b-form-input
                          id="official_phone"
                          v-model="student.official_phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder="+91-1234567890"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Website"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Website"
                        rules="required"
                      >
                        <b-form-input
                          id="url"
                          v-model="student.url"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Industry"
                      label-for="industry"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Industry"
                        rules="required"
                      >
                        <v-select
                          id="industry"
                          v-model="student.industry"
                          :options="industries"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select Industry"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Lifecycle Stage"
                      label-for="student_stage"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Lifecycle Stage"
                        rules="required"
                      >
                        <v-select
                          id="student_stage"
                          v-model="student.student_stage"
                          :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select Lifecycle Stage"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="City"
                      label-for="city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="student.city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="City"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="State"
                      label-for="state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="student.state"
                          :state="errors.length > 0 ? false:null"
                          placeholder="student Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Founders' Details tab -->
            <tab-content
              :before-change="validationFormInfo"
              title="Founders' Details"
            >
              <validation-observer
                ref="studentFounderDetailsRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Founders' Details
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <founders-info
                      v-model="student.startups_founderstables"
                      :founders="startup.startups_founderstables"
                    />
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>

        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import FoundersInfo from '@/views/startup-portal/components/FoundersInfo.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FoundersInfo,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      mutationLoading: false,
      startup: {
        title: null,
        designation: null,
        description: null,
        registered_name: null,
        cin: null,
        dpiit: null,
        official_email: null,
        official_phone: null,
        url: null,
        industry: null,
        lifecycle_stage: null,
        city: null,
        state: null,
        startups_founderstables: [],
      },

      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  methods: {
    formSubmitted() {
      if (this.startup.startups_founderstables.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please enter startup details',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.addStartup()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.startupBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const founders = this.startup.startups_founderstables.filter(founder => founder.name)
        .map(founder => founder)
      const startupDetails = { ...this.startup }
      // eslint-disable-next-line no-underscore-dangle
      delete startupDetails.assoc_id
      delete startupDetails.designation
      delete startupDetails.startups_founderstables
      const object = {
        users_organizationtable: {
          data: {
            startups_founderstables: {
              data: founders,
              on_conflict: {
                constraint: 'startups_founderstable_pkey',
                update_columns: ['name',
                  'din_number',
                  'designation',
                  'email',
                  'phone',
                  'equity_holding'],
              },
            },
            ...startupDetails,
          },
          on_conflict: {
            constraint: 'users_organizationtable_pkey',
            update_columns: ['title',
              'description',
              'registered_title',
              'cin',
              'dpiit',
              'official_email',
              'official_phone',
              'url',
              'industry',
              'startup_stage',
              'city',
              'state'],
          },
        },
        designation: this.startup.designation,
      }
      if (this.startup.assoc_id) object.id = this.startup.assoc_id
      delete object.assoc_id
      return object
    },
    addStartup() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation update_startup_details($object: users_associationtable_insert_input! ) {
          delete_startups_founderstable(where: {organization_id: {_eq: ${this.startup.id || 0} }}) {
              affected_rows
          }
          insert_users_associationtable_one(on_conflict: {update_columns: designation, constraint: users_associationtable_pkey}, object: $object) {
            id
          }
        }`,
        variables: {
          object: this.mutationObject(),
        },
        update: (store, { data: { insert_users_associationtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_users_associationtable_one.id ? 'Startup Updated' : 'There was an Error',
              icon: 'EditIcon',
              variant: insert_users_associationtable_one.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.startup.refetch()
          this.mutationLoading = false
          this.$router.push({ name: 'startup-portal-startups' })
        },
      })
      // this.resetValues()
      return this.error
    },
  },
  apollo: {
    startup: {
      query() {
        return gql`{
          users_associationtable_by_pk(id: ${this.$route.params.id}) {
            id
            designation
            users_organizationtable {
              id
              title
              description
              registered_title
              cin
              dpiit
              official_email
              official_phone
              url
              industry
              startup_stage
              city
              state
              startups_founderstables{
                id
                name
                din_number
                designation
                email
                phone
                equity_holding
              }
            }
          }
        }`
      },
      update: data => ({
        assoc_id: data.users_associationtable_by_pk.id,
        designation: data.users_associationtable_by_pk.designation,
        ...data.users_associationtable_by_pk.users_organizationtable,
      }),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
